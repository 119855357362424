import React from 'react'
import styled from 'styled-components'
import { Text, Flex, IconButton, ArrowBackIcon, NotificationDot } from 'components/Pancake-uikit'
import { Link } from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import { Heading } from '@phamphu19498/bami-uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height:115px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-width: 550px;
  width: 100%;
  padding-left:20px;
  padding-right:20px;
  background: ${({ theme }) =>  theme.colors.success};
  ${({ theme }) =>  (theme.isDark &&
    "background-image: url('/images/SwapBami/backgroundSwapHeader.png');background-repeat: no-repeat; background-size: cover;"
  )};
`

const HeadingDetail = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: -0.114286px;
  color: ${({ theme }) => (theme.isDark ? theme.colors.textSubtle : '#202224')};
`

const DetailDescription = styled(Heading)`
  color: ${({ theme }) =>  theme.colors.TagX};
  text-shadow: 2px 2px 0px #000000;
  font-family: Lalezar;
  font-size:36px;
`

const ImageFlex = styled.div`
  img {
    filter: ${({ theme }) =>
      theme.isDark ? 'invert(64%) sepia(7%) saturate(770%) hue-rotate(180deg) brightness(90%) contrast(88%)' : 'invert(0%) sepia(0%) saturate(4728%) hue-rotate(336deg) brightness(86%) contrast(107%)'};
  }
`
const CustomQuestionHelper = styled(QuestionHelper)`
  * > svg {
    fill: ${({ theme }) =>  theme.colors.secondaryBright};
  }
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()
  const { theme } = useTheme()
  return (
    <AppHeaderContainer>
      <Flex
        style={{
          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
          paddingRight: '5px',
          flex: 1,
        }}
        alignItems="center"
        mr={noConfig ? 0 : '16px'}
      >
        {backTo && (
          <IconButton as={Link} to={backTo}>
            <ArrowBackIcon color={theme.colors.secondaryBright} width="32px" />
          </IconButton>
        )}
        <Flex flexDirection="column">
          <DetailDescription scale='xl'>{title}</DetailDescription>
          <Flex alignItems="center">
            {helper && <CustomQuestionHelper text={helper} mr="4px" />}
            {subtitle && <Heading scale='md'>{subtitle}</Heading>}
          </Flex>
        </Flex>
      </Flex>
      {!noConfig && (
        <ImageFlex>
          <NotificationDot show={expertMode}>
            <GlobalSettings />
          </NotificationDot>
          <Transactions />
        </ImageFlex>
      )}
    </AppHeaderContainer>
  )
}

export default AppHeader
