import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <img
      src="/images/logo-mobile.png"
      style={{ paddingTop: '10px', transform: 'translateX(-15px)', minHeight: '40px', width: 'auto', height: '42px' }}
      alt="Bami"
    />
  );
};

export default Icon;
