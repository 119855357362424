import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="100%" height="auto" viewBox="0 0 390 255" {...props}>
<g>
<path d="M370.993 55.9903C292.994 2.0456 52.9989 -10.9011 15.4997 8.51896H-2V238.324C389.326 243.358 1198.58 312.12 1200.98 266.375C1203.98 209.193 924.982 153.091 825.483 163.88C725.985 174.669 448.991 109.935 370.993 55.9903Z" fill="#BB5432"/>
<path d="M370.993 130.356C292.994 98.2187 52.9989 90.5057 15.4997 102.075H-2V238.98C389.326 241.979 1198.58 282.944 1200.98 255.691C1203.98 221.626 924.982 188.203 825.483 194.631C725.985 201.058 448.991 162.493 370.993 130.356Z" fill="#3C8B8F"/>
<path d="M371.993 184.642C293.994 163.791 53.9989 158.786 16.4997 166.293H-1V255.118C390.326 257.064 1199.58 283.642 1201.98 265.96C1204.98 243.858 925.982 222.173 826.483 226.344C726.985 230.514 449.991 205.493 371.993 184.642Z" fill="#F5A251"/>
</g>

    </Svg>
  );
};

export default Icon;
