import styled from 'styled-components'
import { Box } from '@phamphu19498/bami-uikit'

const Card = styled(Box)<{
  width?: string
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ theme }) => theme.colors.background};
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.background};
  background:  ${({ theme }) => (theme.isDark ? theme.colors.background : "#FFFAF1" )};
`

export const LightGreyCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background:  ${({ theme }) => (theme.isDark ? theme.colors.background : "#FFFAF1" )};
`

export const GreyCard = styled(Card)`
  background:  ${({ theme }) => (theme.isDark ? theme.colors.background : "#FFFAF1" )};
`
export const CardWidthLogo = styled(Card)`
  background:  ${({ theme }) => (theme.isDark ? theme.colors.gradients.backgroundModal : theme.colors.background)};
  border-radius:10px;
  margin-bottom:20px;
  display: flex;
  flex-direction:row;
  align-items:flex-start;
  border:1px solid ${({ theme }) => theme.colors.TagX};
  box-shadow:none;
  @media screen and (max-width: 600px) {
    width:auto !important;
    margin-left:5px;
    margin-right:5px;
  }
`
