import styled from 'styled-components'
import { Card } from '@phamphu19498/bami-uikit'



export const StyledCard = styled(Card)<{ isFinished?: boolean }>`
align-self: baseline;
background: ${({ theme }) => theme.colors.input};
border-radius: ${({theme}) => theme.radii.card};
filter: drop-shadow(-2px 2px 8px rgba(255, 255, 255, 0.2));
display: flex;
flex-direction: column;
justify-content: space-around;
position: relative;
text-align: center;
min-width: 300px;
overflow: hidden;
backdrop-filter: blur(20px);

`

export default StyledCard
