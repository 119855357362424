import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme()
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" {...props} style={{fill:"none"}}>
<path d="M14.6667 2.5H1.33334L6.66668 8.80667V13.1667L9.33334 14.5V8.80667L14.6667 2.5Z" stroke={theme.isDark ? "#E4DFD8" : "#130A00"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
