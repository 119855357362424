import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="9" height="10" viewBox="0 0 9 10" {...props}>
<circle cx="4.5" cy="5" r="4.5"/>
    </Svg>
  );
};

export default Icon;
