import React from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { Button, Heading, Text, LogoIcon, useMatchBreakpoints } from '@phamphu19498/bami-uikit'
// import Page from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import ComingSoonAnimation from '../config/ComingSoonAnimation.json'

const StyledComingSoon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`

const CustomHeading = styled(Heading)`
  font-family: Lalezar;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 56px;
  letter-spacing: -0.45px;
  margin-top:20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.TagX};
  text-shadow: 2px 2px 0px ${({ theme }) => theme.isDark ? "#AEAEAE" : "#BB5432"};
`
const CustomPage = styled.div`
  width:100%;
  height: calc(100vh - 64px);
  over-flow:hidden;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.isDark ? 'background: #1F1F1F' : `background-image: url("/images/BackgroundBami/Background-repeat-x.jpg")`};
`

const ComingSoon = () => {
  const { t } = useTranslation()
  const { isXl } = useMatchBreakpoints();
  const isMobile = isXl === false;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ComingSoonAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <CustomPage>
      <Lottie 
        options={defaultOptions}
        height={ isMobile ? 225 : 291}
        width={isMobile ? 360 : 467}
      />
      <CustomHeading>{t("Coming soon")}...</CustomHeading>
    </CustomPage>
  )
}

export default ComingSoon
