import { ChainId } from '@pancakeswap/sdk'
import { AssetToken } from './types'
import tokens  from './tokens'

const digitalAssetToken: AssetToken[] = [
    {
      "name": "Binance Pegged BUSD",
      "symbol": "BUSD",
      "address": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png"
    },
    {
      "name": "Binance Pegged USDT",
      "symbol": "USDT",
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png"
    },
    {
      "name": "LiveTrade Token",
      "symbol": "LTD",
      "address": "0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/images/coins/0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4.svg"
    },
    {
      "name": "PancakeSwap Token",
      "symbol": "CAKE",
      "address": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png"
    },
    {
      "name": "Cardano Token",
      "symbol": "ADA",
      "address": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47.png"
    },
    {
      "name": "Bami",
      "symbol": "BAMI",
      "address": "0xe2d3486f46efbd4199ea087e9e466dcc35ee0248",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/images/coins/0xe2d3486f46efbd4199ea087e9e466dcc35ee0248.png"
    },
    {
      "name": "Moonpot",
      "symbol": "POTS",
      "address": "0x3Fcca8648651E5b974DD6d3e50F61567779772A8",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x3Fcca8648651E5b974DD6d3e50F61567779772A8.png"
    },
    {
      "name": "Coin98",
      "symbol": "C98",
      "address": "0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6.png"
    },
    {
      "name": "Sushi",
      "symbol": "SUSHI",
      "address": "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x947950BcC74888a40Ffa2593C5798F11Fc9124C4.png"
    },
    {
      "name": "HeroesTD",
      "symbol": "HTD",
      "address": "0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/images/coins/0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f.png"
    },
]

export const bnbCoin = {
  symbol: 'BNB',
  address: tokens.wbnb.address[ChainId.MAINNET],
  name: 'BNB',
  decimals: tokens.wbnb.decimals,
  logoURI: 'https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png'
}

export default digitalAssetToken;