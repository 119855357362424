import React from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { Box } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { IconButton } from "../../components/Button";
import { ModalProps } from "./types";

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ theme }) => (theme.isDark ? '#101133' : '#101133')};
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  display: flex;
  padding: 12px 24px;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  background: ${({ theme }) => (theme.isDark ? 'transparent' : 'white')};
  @media only screen and (max-width: 550px) {
    padding-bottom:20px;
  }
  @supports not ((-webkit-backdrop-filter: blur(3em)) or (backdrop-filter: blur(3em))) {
    background: ${({ theme }) => (theme.isDark ? 'rgba(16,17,51,0.7)' : '#e2e2e2' )};
    -webkit-backdrop-filter: blur(3em);
    backdrop-filter: blur(3em);
}
`;

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps["onDismiss"] }> = ({ onDismiss }) => {
  return (
    <IconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="primary" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="primary" />
    </IconButton>
  );
};

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  background: ${({ theme }) => (theme.isDark ? 'linear-gradient(106.94deg, rgba(255, 255, 255, 0.17) 24.69%, rgba(255, 255, 255, 0.1) 82.76%)' : 'white')};
  backdrop-filter: blur(90px);
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 32px;
  width: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};

  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    min-width: ${({ minWidth }) => minWidth};
    max-width: 100%;
  }
  @media only screen and (max-width: 550px) {
    width:95% !important;
    margin:0px;
    padding:0px;
  }
`;
export const ModalTitleLogo = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width:100%;
    padding:15px 0 5px 0;
    margin-left:20px;
    > h2 {
      font-size:18px !important;
      font-weight:700;
      margin-top:10px;
    }
`