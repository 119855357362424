import React, { useState } from 'react'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  Modal
} from '@phamphu19498/bami-uikit'
import { Heading} from 'components/Pancake-uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'



export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = new BigNumber('2000000000') // 2 Gwei

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({theme}) => theme.colors.textDark};
`

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 16px 24px;
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    // <ModalContainer maxWidth="550px" minWidth="320px">
    //   <ModalHeader>
    //     <ModalTitle>
    //       <Heading>{t('Your Wallet')}</Heading>
    //     </ModalTitle>
    //     <IconButton variant="text" onClick={onDismiss}>
    //       <CloseIcon width="24px" color="#fdb533" />
    //     </IconButton>
    //   </ModalHeader>
    //   <Tabs>
    //     <ButtonMenu scale="sm" variant="subtle" onItemClick={handleClick} activeIndex={view} fullWidth>
    //       <ButtonMenuItem>{t('Wallet')}</ButtonMenuItem>
    //       <ButtonMenuItem>{t('Transactions')}</ButtonMenuItem>
    //     </ButtonMenu>
    //   </Tabs>
    //   <ModalBody p="24px" maxWidth="400px" width="100%">
    //     {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
    //     {view === WalletView.TRANSACTIONS && <WalletTransactions />}
    //   </ModalBody>
    // </ModalContainer>
    <Modal title={t('Your Wallet')} onDismiss={onDismiss}>
        <ModalBody>
            <ButtonMenu scale="sm" onItemClick={handleClick} activeIndex={view} fullWidth>
                <ButtonMenuItem>{t('Wallet')}</ButtonMenuItem>
                <ButtonMenuItem>{t('Transactions')}</ButtonMenuItem>
            </ButtonMenu>
            <div style={{marginTop:"20px"}}>
                {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
                {view === WalletView.TRANSACTIONS && <WalletTransactions />}
            </div>
        </ModalBody>
    </Modal>
  )
}

export default WalletModal
