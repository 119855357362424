import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { Flex, Text, Box } from '@phamphu19498/bami-uikit'
import { useTranslation } from 'contexts/Localization'
import { PoolCategory } from 'config/constants/types'
import { Pool } from 'state/types'
import ApprovalAction from './ApprovalAction'
import StakeActions from './StakeActions'
import HarvestActions from './HarvestActions'



const InlineText = styled(Text)`
  display: inline;
`
const Wrapper = styled.div`
  background: ${({ theme }) => theme.isDark ? "#000" : " #FFFAF1"};
  border-radius:5px;
  padding:10px;
`

interface CardActionsProps {
  pool: Pool
  stakedBalance: BigNumber
}

const CardActions: React.FC<CardActionsProps> = ({ pool, stakedBalance }) => {
  const { sousId, stakingToken, earningToken, harvest, poolCategory, userData, earningTokenPrice } = pool
  // Pools using native BNB behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE
  const { t } = useTranslation()
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO
  const needsApproval = !allowance.gt(0) && !isBnbPool
  const isStaked = stakedBalance.gt(0)
  const isLoading = !userData

  return (
    <Flex flexDirection="column">
        {harvest && (
          <Wrapper>
            <Flex>
              <InlineText color="text"  bold fontSize="16px">
                {`${earningToken.symbol} `}
              </InlineText>
              <InlineText color="text" bold fontSize="16px" ml='2.2px'>
                {t('Earned')}
              </InlineText>
            </Flex>
            <HarvestActions
              earnings={earnings}
              earningToken={earningToken}
              sousId={sousId}
              earningTokenPrice={earningTokenPrice}
              isBnbPool={isBnbPool}
              isLoading={isLoading}
            />
          </Wrapper>
        )}
        <Flex mt="20px">
          <InlineText color={isStaked ? 'text' : 'text'} textTransform="uppercase" bold fontSize="16px">
            {isStaked ? t('Staked') : `${stakingToken.symbol}`}
          </InlineText>
          <InlineText color={isStaked ? 'primary' : 'textSubtle'} ml="4px" bold fontSize="16px">
              {isStaked ? stakingToken.symbol : t('Staked')}{' '}
          </InlineText>
        </Flex>
        {needsApproval ? (
          <ApprovalAction pool={pool} isLoading={isLoading} />
        ) : (
          <StakeActions
            isLoading={isLoading}
            pool={pool}
            stakingTokenBalance={stakingTokenBalance}
            stakedBalance={stakedBalance}
            isBnbPool={isBnbPool}
            isStaked={isStaked}
          />
        )}
    </Flex>
  )
}

export default CardActions
