import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";


const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="305" height="183" viewBox="0 0 305 183" {...props}>
        <path d="M0 183L138.932 0H305V183H0Z" fill="#3B98BC"/>
    </Svg>
  );
};

export default Icon;
