import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme()
  return (
    <Svg width="139" height="139" viewBox="0 0 159 159" {...props}>
        <circle cx="79.5" cy="79.5" r="76.5" fill={theme.colors.secondaryBright} stroke={theme.colors.TagX} strokeWidth="6"/>
    </Svg>
  );
};

export default Icon;
