// import React from 'react'
// import styled from 'styled-components'
// import { Card } from '@phamphu19498/bami-uikit'

// export const BodyWrapper = styled(Card)`
//   border-radius: 24px;
//   max-width: 436px;
//   width: 100%;
//   z-index: 1;
// `

// /**
//  * The styled container element that wraps the content of most pages and the tabs.
//  */
// export default function AppBody({ children }: { children: React.ReactNode }) {
//   return <BodyWrapper>{children}</BodyWrapper>
// }
import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 550px;
  width: 100%;
  z-index: 5;
  background: ${({ theme }) => theme.isDark ? "#151515" : theme.colors.input};
  backdrop-filter: blur(20px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px 16px 0px;
  margin-bottom:30px;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
