import React from 'react'
import { CardHeader, Heading, Text, Flex, Box } from '@phamphu19498/bami-uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Token } from 'config/constants/types'
import { TokenPairImage } from 'components/TokenImage'
import CakeVaultTokenPairImage from '../CakeVaultCard/CakeVaultTokenPairImage'



const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string }>`
  height: 93px;
  background:none;
`
const CustomHeading = styled(Heading)`
background:${({ theme }) => theme.isDark ? "#2C2A29" : theme.colors.secondaryBright};
color:${({ theme }) => theme.colors.success};
padding:4px 7px 4px 7px;
border-radius:5px;
margin-top:3px;
text-transform: uppercase;
font-size: 18px;
font-family: Lalezar;
`
const StyledCardHeader: React.FC<{
  earningToken: Token
  stakingToken: Token
  isAutoVault?: boolean
  isFinished?: boolean
  isStaking?: boolean
}> = ({ earningToken, stakingToken, isFinished = false, isAutoVault = false, isStaking = false }) => {
  const { t } = useTranslation()
  const isCakePool = earningToken.symbol === 'CAKE' && stakingToken.symbol === 'CAKE'
  const background = isStaking ? 'bubblegum' : 'cardHeader'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return t('Auto')
    }
    if (isCakePool) {
      // manual cake
      return t('Manual')
    }
    // all other pools
    return t('Earn')
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return t('Automatic restaking')
    }
    if (isCakePool) {
      return t('Earn CAKE, stake CAKE')
    }
    return t('Stake %symbol%', { symbol: stakingToken.symbol })
  }

  return (
    <Wrapper isFinished={isFinished} background={background}>
        <Flex flexDirection="row">
         
          {isAutoVault ? (
            <CakeVaultTokenPairImage width={64} height={64} />
          ) : (
            <TokenPairImage primaryToken={earningToken} secondaryToken={stakingToken} width={64} height={64} />
          )}
          <Flex flexDirection="column" ml="10px">
              <CustomText fontSize='20px' fontWeight={600}>{getSubHeading()}</CustomText>
              <CustomHeading scale="md">
                {`${getHeadingPrefix()} ${earningToken.symbol}`}
              </CustomHeading>
            </Flex>
        </Flex>
      
    </Wrapper>
  )
}

export default StyledCardHeader

const CustomText = styled(Text)`
  background:${({ theme }) => theme.isDark ? "#2C2A29" : theme.colors.secondaryBright};
  color:${({ theme }) => theme.colors.TagX};
  padding:3px 7px 3px 7px;
  border-radius:5px;
  text-transform: uppercase;
  font-size: 18px;
  font-family: Lalezar;
`