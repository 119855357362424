import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme()
  return (
    <Svg width="13" height="12" viewBox="0 0 13 12" {...props}>
<g>
<path d="M11.6364 1H3.86364C3.6348 1.0007 3.41552 1.09458 3.25371 1.26113C3.09189 1.42767 3.00068 1.65336 3 1.88889V8.11111C3.00068 8.34664 3.09189 8.57233 3.25371 8.73887C3.41552 8.90542 3.6348 8.9993 3.86364 9H11.6364C11.8652 8.9993 12.0845 8.90542 12.2463 8.73887C12.4081 8.57233 12.4993 8.34664 12.5 8.11111V1.88889C12.4993 1.65336 12.4081 1.42767 12.2463 1.26113C12.0845 1.09458 11.8652 1.0007 11.6364 1ZM11.6364 8.11111H3.86364V1.88889H11.6364V8.11111Z" fill={theme.colors.text}/>
<path d="M7.16667 10.6429H1.33333V6.35714H2.16667V5.5H1.33333C1.11252 5.50068 0.900944 5.5912 0.744806 5.7518C0.588669 5.9124 0.500659 6.13002 0.5 6.35714V10.6429C0.500659 10.87 0.588669 11.0876 0.744806 11.2482C0.900944 11.4088 1.11252 11.4993 1.33333 11.5H7.16667C7.38748 11.4993 7.59906 11.4088 7.75519 11.2482C7.91133 11.0876 7.99934 10.87 8 10.6429V9.78571H7.16667V10.6429Z" fill={theme.colors.text}/>
</g>
<defs>
<clipPath id="clip0_892_36039">
<rect width="12" height="12" fill={theme.colors.text} transform="translate(0.5)"/>
</clipPath>
</defs>

    </Svg>
  );
};

export default Icon;
