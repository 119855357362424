import React from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  UserMenu as UIKitUserMenu,
  UserMenuItem,
  useModal
} from '@phamphu19498/bami-uikit'
import { useHistory } from 'react-router';
import useAuth from 'hooks/useAuth'
import { CheckWhiteList } from "views/AdminRuntogether/hook/fetchDataUser"
import ConnectWalletButton from 'components/ConnectWalletButton'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'


const UserMenu = () => {
  const { t } = useTranslation()
  const history = useHistory();
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const avatarSrc =  undefined
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
  const { isCheckWL } = CheckWhiteList()
  const AdminShopRun = () => {
    history.push(`/adminruntogether`)
  }
  const MovetoYourNFts = () => {
    history.push(`/runtogethernft`)
  }
  const MoveMyNft = () => {
    history.push(`/mynfts`)
  }
  if (!account) {
    return (
        <ConnectWalletButton scale="sm" />
    )
  }

  return (
    <UIKitUserMenu account={account} avatarSrc={avatarSrc}>
      <WalletUserMenuItem hasLowBnbBalance={hasLowBnbBalance} onPresentWalletModal={onPresentWalletModal} />
      {/* <UserMenuItem as="button" onClick={MovetoYourNFts} >
        {t('Run Together NFTs')}
      </UserMenuItem> */}
      <UserMenuItem as="button" onClick={MoveMyNft}>
          {t('My NFTs')}
        </UserMenuItem>
      <UserMenuItem as="button" onClick={onPresentTransactionModal}>
          {t('Transactions')}
        </UserMenuItem>
      {/* { isCheckWL === true &&
        <UserMenuItem as="button" onClick={AdminShopRun}>
          {t('Admin shop RTBN')}
        </UserMenuItem>
      } */}
      <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </UserMenuItem>
    </UIKitUserMenu>
  )
}

export default UserMenu
