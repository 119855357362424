import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { useSaveReferrer  } from 'state/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import ResetCSS  from './style/ResetCSS'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import Pools from './views/Pools'
import Swap from './views/Swap'
import ComingSoon from './views/ComingSoon'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'
import Bridge from './views/Bridge'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Dashboard/index'))
const Farms = lazy(() => import('./views/Farms'))
const MiniFarms = lazy(() => import('./views/MiniFarms'))
const FarmAuction = lazy(() => import('./views/FarmAuction'))
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Membership = lazy(() => import('./views/Membership'))
const Airdrop = lazy(() => import('./views/Airdrop'))
const Project = lazy(() => import('./views/Dipo/components/Project'))
const DetailProject = lazy(() => import('./views/Dipo/components/Project/ProjectDetail/index'))
const YourNFTs = lazy(() => import('./views/YourNFTs/NFTs'))
const RedeemTransferp2p = lazy(() => import('./views/Redeem/index'))
const UpgradeLevel = lazy(() => import('./views/Membership/UpgradeLevel/index'))
const AirdropDetail = lazy(() => import('./views/Airdrop/AirdropDetail/index'))
const COLLATERALS = lazy(() => import('./views/COLLATERALS/index'))
const Lending = lazy(() => import('./views/LENDING/index'))
const PAWN = lazy(() => import('./views/PAWN/index'))
const DetailsUpgrade = lazy(() => import('./views/LENDING/DetailsUpgrade/index'))
const NFTPools = lazy(() => import('./views/NFTPools/index'))
const PawnPools = lazy(() => import('./views/PAWNPools/index'))
const PawnManagement = lazy(() => import('./views/PawnManagement/index'))
const PrivateShare = lazy(() => import('./views/PrivateShare/components/Project/index'))
const DetailPrivateShare = lazy(() => import('./views/PrivateShare/components/Project/ProjectDetail/index'))
const DetailIdo1 = lazy(() => import('./views/IDO/Pool1/index'))
const DetailIdo3 = lazy(() => import('./views/IDO/Pool3/index'))
const DetailIdo2 = lazy(() => import('./views/IDO/Pool2/index'))
const IDO = lazy(() => import('./views/IDO/index'))
const IdoDetails = lazy(() => import('./views/IDO/components/Deatils/index'))
const AdminPawnShop = lazy(() => import('./views/AdminPawnShop/index'))
const Detailsell = lazy(() => import('./views/AdminPawnShop/details/index'))
const DetailBuy = lazy(() => import('./views/COLLATERALS/details/index'))
const AdminRuntogether = lazy(() => import('./views/AdminRuntogether/index'))
const DetailSellRuntogether = lazy(() => import('./views/AdminRuntogether/details/index'))
const CollectiblesRTBN = lazy(() => import('./views/CollectiblesRTBN/index'))
const PageBuyRun = lazy(() => import('./views/CollectiblesRTBN/BuyPage'))
const NftRTBN = lazy(() => import('./views/YourNFTs/NftRTBN'))
const Apply = lazy(() => import('./views/Apply/index'))
// This config is required for number formatting Membership  
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useSaveReferrer()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      {/* <GlobalCheckClaimStatus excludeLocations={['/collectibles']} /> */}
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route exact path="/farms/auction">
              <FarmAuction />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/mini-farms">
              <MiniFarms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/vote">
              <ComingSoon/>
            </Route>
            <Route path="/membership">
              {/* <Membership/> */}
              <ComingSoon/>
            </Route>
            <Route path="/airdrop">
              <Airdrop/>
            </Route>
            <Route path="/bridge">
              <Bridge/>
            </Route>
            <Route path="/nftmarketplace">
              <ComingSoon/>
            </Route>
            <Route path="/mynfts">
              {/* <YourNFTs/> */}
              <ComingSoon/>
            </Route>
            <Route path="/market">
              <COLLATERALS/>
            </Route>
            <Route path="/lending">
              <Lending/>
            </Route>
            <Route path="/pawn">
              <PAWN/>
            </Route>
            <Route path="/nftpools">
              {/* <NFTPools/> */}
              <ComingSoon/>
            </Route>
            {/* <Route path="/pawnpools">
              <PawnPools/>
            </Route> */}
            {/* <Route path="/pawnmanagement">
              <PawnManagement/>
            </Route> */}
            <Route path="/adminpawnshop">
              <AdminPawnShop/>
            </Route>
            <Route path="/adminruntogether"> 
              <AdminRuntogether/>
            </Route>
            <Route path="/runmarket"> 
              <CollectiblesRTBN/>
            </Route>
            <Route path="/runtogethernft"> 
              <NftRTBN/>
            </Route> 
            <Route path="/apply"> 
              <Apply/>
            </Route>
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
            <Route exact path="/redeem/:level" component={RedeemTransferp2p} />
            <Route exact path="/upgrade/:level" component={UpgradeLevel} />
            <Route exact path="/airdropdetail/:airdropId" component={AirdropDetail} />
            <Route exact path="/project/:project" component={DetailProject} />
            <Route exact path="/lendingupgrade/:level" component={DetailsUpgrade} />
            <Route exact path="/privatesharedetails/:project" component={ComingSoon} />
            <Route exact path="/detailsell/:id" component={Detailsell} />
            <Route exact path="/idodetails/pool1" component={DetailIdo1} />
            <Route exact path="/idodetails/pool2" component={DetailIdo2} />
            <Route exact path="/idodetails/pool3" component={DetailIdo3} />
            {/* <Route exact path="/idodetails/pool4" component={DetailIdo4} /> */}
            {/* <Route exact path="/detailsellruntogether/:id" component={DetailSellRuntogether} /> */}
            <Route exact path="/detailbuy/:id" component={DetailBuy} />
            {/* <Route path="/launchpad">
              <Project/>
            </Route> */}
            <Route path="/privateshare">
              <ComingSoon/>
            </Route>
            <Route path="/pool">
              
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            <Route path="/nft">
              <Redirect to="/collectibles" />
            </Route>
            <Route path="/ido">
              <IDO/>
            </Route>
            
            
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      {/* <EasterEgg iterations={2} /> */}
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
