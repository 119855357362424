import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, BackgroundSVG, BackgroundSvgMobile } from 'components/Pancake-uikit'
import { useTranslation } from 'contexts/Localization'
import Page from 'components/Layout/Page'

const ContainerSVG = styled.div`
  width:100%;
  height:auto;
  position: absolute;
  bottom:0px;
  left:0px;
  right:0px;
  > svg {
    display: block;
    height: 100%;
  }
  
`
const CustomPage = styled.div`
  display: flex;
  justify-content: flex-start;
  width:100%;
  flex-direction: column;
  @media screen and (min-width: 1440px) {
    min-height:100vh;
    padding-bottom:140px;
  }
  ${({ theme }) => theme.isDark ? 'background: #100E0C' : `background-image: url("/images/BackgroundBami/Background-repeat-x.jpg")`};
  @media only screen and (max-width: 1080px) {
    padding-bottom:210px;
    min-height:auto;
  }
`
const ImgFooter = styled.img<{ height: string; width: string }>`
  position: absolute;
  width:${({ width }) => width};
  height:${({ height }) => height};
  bottom:0px;
`
const PageWidthBG: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {

  const { t } = useTranslation()
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  useEffect(() => {
      const handleResize = () => {
          setWindowSize(window.innerWidth)
      }

      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <CustomPage>
      {children}
      <ContainerSVG>
        { windowSize > 600 ?
          <BackgroundSVG/>
        :
          <BackgroundSvgMobile/>
        }
        
      </ContainerSVG>
      <ImgFooter 
          src="/images/HomeBami/imagesRight.png" 
          alt="background" 
          width={ windowSize > 1080 ? "225px" : "116px" } 
          height={ windowSize > 1080 ? "286px" : "147px" } 
          style={{right: windowSize > 600 ? "30px": "0px"}}
      />
      <ImgFooter 
          src="/images/HomeBami/imagesLeft.png" 
          alt="background" 
          width={ windowSize > 1080 ? "367px" : "216px" }  
          height={ windowSize > 1080 ? "373px" : "220px" }  
          style={{left:windowSize > 600 ? "30px": "0px"}}
      />
    </CustomPage>
  )
}

export default PageWidthBG
