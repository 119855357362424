import {Conditions, Memberships} from "views/Membership/config"
import tokens from "config/constants/tokens"

const nftRequired = () => {
    const arr = []
    for(let i = 0; i < Conditions.length ; i++){
        if(Conditions[i].title === 'Diamond' || Conditions[i].title ===  'Platinum' || Conditions[i].title ===  'Gold' || Conditions[i].title ===  'Elite'){
            arr.push(Conditions[i])
        }
    }
    return arr 
} 

export const privateShareList = [
    {
        id: '1',
        name: 'DARKLAND',
        status: 'Fixed Price',
        nftRequire: [Memberships.Diamond, Memberships.Platinum, Memberships.Gold, Memberships.Elite],
        stakingAddress: {
            97: '0x136B1A0B90759511a21EfBe0f7c1823b8aBC8BD5',
            56: '0xccD35293d3ecd1a3ab91078b68AAab28F5591dA3'
        },
        idoAddress: {
            97: '0xfAa66a3D1fe9110f1352b21Afd7B31bBBf33Fb39',
            56: '0x8C6f2129CACfc191058c7dA7AaAf9a1E5efA8cB9'
        },
        image: '/images/coins/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d.png',
        description: 'HeroesTD is a blockchain-based Play to Earn game where players summon Heroes to attack enemy bases and defend their bases. As an NFT game, HeroesTD provides players with diverse Heroes and game modes to enjoy and earn real money.',
        purchaseWith: 'BUSD',
        project:"DARKLAND",
        requiredLevel: '10,000.00 BAMI',
        endsIn: '00:00:00',
        startsOn : "Nov 28, 2021",
        startsIn : "12:00 PM (UTC+7) November 28, 2021",
        currentRound: 'Private',
        liveStatus: 'live',
        tokenPrice: tokens.busd,
        tokenEarn: tokens.hesman,
        tokenFee: tokens.bami,
        perPrice: 0.05,
        maxAllcation:100,
        fee:1000,
        totalSlots:6,
        percentage: 100,
        minPrice: 100,
        stakingEndTime:1647832294000,
        fixedPrice: '1 HTD ~ 0.04 BUSD',
        value: '98/98 slots',
        whitepaperLink:"https://docs.heroestd.io/",
        contractAddressLink:"https://bscscan.com/token/0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f",
        Issuer:"CG Studio Pte. Ltd.",
        projectname:"darkland",
        bannerProject:"banner_project_1.png",
        purchase:100,
        Allocation:100,
        SocialLink: [
            {id:1, Label:"Globe", Icon:"GlobeIcon", Link:"/"},
            {id:2, Label:"Twitter", Icon:"TwitterIcon", Link:"/"},
            {id:3, Label:"Telegram", Icon:"TelegramIcon", Link:"/"},
            {id:4, Label:"Facebook", Icon:"FacebookIcon", Link:"/"},
            {id:5, Label:"Discord", Icon:"DiscordIcon", Link:"/"},
        ],
        detail:{
            DataTotalAllocation:[
                {id: 1, name:"Total allocation", value:"100"},
                {id: 2, name:"Vesting period", value:""},
                {id: 3, name:"Vesting Starts", value:""},
                {id: 4, name:"Min/Max Entry", value:"10-200"},
                {id: 5, name:"Cliff", value:""},
                {id: 6, name:"Required Level", value:"Elite+"}, 
            ],
            DataYourInvestment:[
                {id:1,Round:"Private", Purchased:"",Purchaseprice:"",Claimed:"", Nextvestingin:"",Claimable:"",button:"Claim vested"},
            ]
        },
        VestingSchedule:["6% unlock at TGE, 90-day cliff,","6% monthly unlock in the next 16 months"],
        ListLevel: [
            {id:1, name:"Diamond", icon:"/images/membership/ranks/Diamond.svg", Allocation:"$200"},
            {id:2, name:"Platinum", icon:"/images/membership/ranks/Platinum.svg", Allocation:"$150"},
            {id:3, name:"Gold", icon:"/images/membership/ranks/Gold.svg", Allocation:"$100"},
            {id:4, name:"Elite", icon:"/images/membership/ranks/Silver.svg", Allocation:"$50"},
        ]
    },
    {
        id: '2',
        name: 'Hesman',
        status: 'Fixed Price',
        stakingAddress: {
            97: '0x6D9254B235b2244BFFcAAEC6c697b5F1c65EC3Eb',
            56: ''
        },
        image: '/images/coins/0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0.png',
        description: 'HeroesTD is a blockchain-based Play to Earn game where players summon Heroes to attack enemy bases and defend their bases. As an NFT game built on Blockchain, HeroesTD provides players with diverse Heroes and game modes to enjoy and earn real money.',
        purchaseWith: 'BUSD, BAMI',
        project:"Hesman",
        requiredLevel: '5,000 BAMI',
        endsIn: '00:00:00',
        startsOn : "00:00:00",
        startsIn : "12:00 PM (UTC+7) November 28, 2021",
        currentRound: 'Private',
        liveStatus: 'live',
        tokenPrice: tokens.busd,
        tokenEarn: tokens.hesman,
        tokenFee: tokens.bami,
        totalEarn:5000,
        perPrice: 0.05,
        maxAllcation:50,
        fee:5300,
        totalSlots:100,
        totalPerSolots:5000,
        percentage: 100,
        totalLock:5000,
        totalFee:300,
        minPrice: 100,
        stakingEndTime:1647832294000,
        fixedPrice: '1 HTD ~ 0.04 BUSD',
        value: '98/98 slots',
        whitepaperLink:"https://docs.hesman.net/hesman-legend/du-an-hesman-legend/ve-hesman-legend",
        contractAddressLink:"https://bscscan.com/address/0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0",
        Issuer:"CG Studio Pte. Ltd.",
        projectname:"pool1",
        bannerProject:"banner_project_1.png",
        purchase:100,
        Allocation:50,
        SocialLink: [
            {id:1, Label:"Globe", Icon:"GlobeIcon", Link:"https://hesman.net/"},
            {id:2, Label:"Twitter", Icon:"TwitterIcon", Link:"https://twitter.com/HesmanLegend"},
            {id:3, Label:"Telegram", Icon:"TelegramIcon", Link:"https://t.me/HesmanLegend"},
            {id:4, Label:"Facebook", Icon:"FacebookIcon", Link:"https://www.facebook.com/hesman.net"},
        ],
        VestingSchedule:["25% unlock at TGE, 30-day cliff,","25% monthly unlock in the next 3 months"],
    },
    {
        id: '3',
        name: 'Hesman',
        status: 'Fixed Price',
        stakingAddress: {
            97: '0xe72BC3f037FEF4bF4e0a8C366Da2aaC5f245C435',
            56: ''
        },
        image: '/images/coins/0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0.png',
        description: 'HeroesTD is a blockchain-based Play to Earn game where players summon Heroes to attack enemy bases and defend their bases. As an NFT game built on Blockchain, HeroesTD provides players with diverse Heroes and game modes to enjoy and earn real money.',
        purchaseWith: 'BUSD, BAMI',
        project:"Hesman",
        requiredLevel: '1,000 BAMI',
        endsIn: '00:00:00',
        startsOn : "00:00:00",
        startsIn : "12:00 PM (UTC+7) November 28, 2021",
        currentRound: 'Private',
        liveStatus: 'live',
        tokenPrice: tokens.busd,
        tokenEarn: tokens.hesman,
        tokenFee: tokens.bami,
        totalEarn:2500,
        perPrice: 0.05,
        maxAllcation:50,
        fee:5300,
        totalSlots:50,
        percentage: 100,
        totalLock:5000,
        totalFee:300,
        totalPerSolots:2500,
        minPrice: 50,
        stakingEndTime:1647832294000,
        whitepaperLink:"https://docs.hesman.net/hesman-legend/du-an-hesman-legend/ve-hesman-legend",
        contractAddressLink:"https://bscscan.com/address/0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0",
        Issuer:"CG Studio Pte. Ltd.",
        projectname:"pool3",
        bannerProject:"banner_project_1.png",
        purchase:100,
        Allocation:50,
        SocialLink: [
            {id:1, Label:"Globe", Icon:"GlobeIcon", Link:"https://hesman.net/"},
            {id:2, Label:"Twitter", Icon:"TwitterIcon", Link:"https://twitter.com/HesmanLegend"},
            {id:3, Label:"Telegram", Icon:"TelegramIcon", Link:"https://t.me/HesmanLegend"},
            {id:4, Label:"Facebook", Icon:"FacebookIcon", Link:"https://www.facebook.com/hesman.net"},

        ],
        VestingSchedule:["25% unlock at TGE, 30-day cliff,","25% monthly unlock in the next 3 months"],
    }
]