import { Colors } from "./types";

export const baseColors = {
  failure: "#CB5489",
  primary: "#FF9329",
  primaryBright: "#BE8F63",
  primaryDark: "#3B98BC",
  secondary: "#BB5432",
  success: "#3C8B8F",
  warning: "#FFB237",
  bright: "#FFC965",
  redeem:"#56A9BB",
  secondaryDark:"#2C2A29",
  TagX:"#BB5432",
  secondaryBright:"#F9DFAF",
  Neutral:"#FAF1E1",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#452a7a",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
};

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: "#F9DFAF",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#F9DFAF",
  cardBorder: "#FFFFFF",
  contrast: "#191326",
  dropdown: "#F6F6F6",
  dropdownDeep: "#EEEEEE",
  invertedContrast: "#FFFFFF",
  input: "#FAF1E1",
  inputSecondary: "#E4E7EB",
  tertiary: "#353547",
  backgroundHover:"rgba(60, 139, 143, 0.2);",
  text: "#0C0700",
  textDisabled: "#696969",
  textSubtle: "#000000",
  disabled: "#E9EAEB",
  gradients: {
    bubblegum: "white",
    inverseBubblegum: "white",
    cardHeader: "linear-gradient(111.68deg, #101133 0%, #101133 100%)",
    blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
    violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
    violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
    backgroundContainer: "linear-gradient(180deg, #F8F8FF 0%, #F8F8FF 100%)",
    backgroundUpgrade:"linear-gradient(106.94deg, rgba(255, 255, 255, 0.17) 24.69%, rgba(255, 255, 255, 0.1) 82.76%)",
    backgroundUserInfor:"linear-gradient(151.53deg, #D9DAFF 8.5%, #CCDDFF 97.77%)",
    backgroundNftInfo: "#EFF0FF",
    backgroundModal:"#FAF1E1",
    backgroundCardSWAP:"#fff",
  },
  backgroundTab: '#FAFBFC',
  textTab: '#8A94A6',
  borderTab: '#E4E7EB',
  textDark: '#101133',
  borderLine: '#E2E2E2',
  membershipwrapperheaderbackground:"#101133",
  dipoMainBackground: '#FFFFFF',
  dipoContainerBackground: '#E8E9FA',
  homePrice: '#010571',
  upgradelevelContainer:"#fff",
  borderUpgradeTableHeader:"#e2e2e2",
  backgroundlight:"rgba(255, 255, 255, 0.1);",
  backgrounddark:"rgba(16, 17, 51, 0.04);",
  backgroundContainerMembership:"#E8E9FA",
  divider: "#e2e2e2",
  backgroundDark:"#fff",
  thirdDark:"#F8F8FF",
  textDarkSecondary:"#F9DFAF",
  bgCardProject:"#F8F8FF",
  textSecondary:"#F9DFAF",
  textThird:"#101133",
  bgPaste:"#D8DAFB",
  containerInput:"#E8E9FA",
  backgroundListview:"#F8F8FF",
  backgroundAcionList:"#E8E9FA",
  backgroundCard:"#FFC965",
  backgroundInputGroup:"#E8E9FA",
  backgroundTabButton:"#FAF1E1",
  TabButtonActive:"#fff",
};

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: "#100E0C",
  secondary: "#9A6AFF",
  backgroundDisabled: "#707085",
  backgroundAlt: "#F9DFAF",
  cardBorder: "#383241",
  contrast: "#FFFFFF",
  dropdown: "#1E1D20",
  dropdownDeep: "#100C18",
  invertedContrast: "#191326",
  input: "#1F1F1F",
  inputSecondary: "#262130",
  tertiary: "#353547",
  backgroundHover: "rgba(60, 139, 143, 0.2);",
  text: "#FBFBFB",
  textDisabled: " rgba(255, 255, 255, 0.6)",
  textSubtle: "#fff",
  disabled: "#524B63",
  gradients: {
    bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
    inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
    cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
    blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
    violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
    violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
    backgroundContainer: "linear-gradient(128.36deg, rgba(255, 255, 255, 0.091) 12.77%, rgba(255, 255, 255, 0.013) 86.37%)",
    backgroundUpgrade:"#393B57;",
    backgroundUserInfor:"linear-gradient(162.57deg, rgba(44, 46, 116, 0.5) 22.42%, rgba(75, 22, 107, 0.5) 82.74%)",
    backgroundNftInfo: "linear-gradient(190.03deg, rgba(44, 46, 116, 0.5) 17.62%, rgba(75, 22, 107, 0.5) 83.22%)",
    backgroundModal:"#151515",
    backgroundCardSWAP:"linear-gradient(128.36deg, rgba(255, 255, 255, 0.091) 12.77%, rgba(255, 255, 255, 0.013) 86.37%)",
  },
  backgroundTab: '#FAFBFC',
  textTab: '#8A94A6',
  borderTab: '#E4E7EB',
  textDark: '#101133',
  borderLine: '#FFF',
  dipoMainBackground: 'linear-gradient(128.36deg, rgba(255, 255, 255, 0.091) 12.77%, rgba(255, 255, 255, 0.013) 86.37%);',
  dipoContainerBackground: 'linear-gradient(109.64deg, rgba(255, 255, 255, 0.105) 13.69%, rgba(255, 255, 255, 0.015) 89.45%)',
  homePrice: '#fdb533',
  membershipwrapperheaderbackground:"#101133",
  upgradelevelContainer:"rgba(255, 255, 255, 0.06)",
  borderUpgradeTableHeader:"rgba(255, 255, 255, 0.3);",
  backgrounddark:"rgba(255, 255, 255, 0.01)",
  backgroundlight :"rgba(255, 255, 255, 0.1)",
  backgroundContainerMembership:"#101133",
  divider:"rgb(82 82 82 / 50%)",
  backgroundDark:"#282947",
  thirdDark:"#1C1D3D",
  textDarkSecondary:"#FBFBFB",
  bgCardProject:"#101133",
  textSecondary:"#A19E95",
  textThird:"#FFFFFF",
  bgPaste:"#282947",
  containerInput:"#101133",
  backgroundListview:"linear-gradient(180deg, rgba(81, 82, 102, 0.5) 24.4%, rgba(71, 71, 96, 0.5) 50%)",
  backgroundAcionList:"rgba(61, 62, 89, 0.2)",
  backgroundCard:"#FFC965",
  backgroundInputGroup:"linear-gradient(330.77deg, #37385A 0.25%, #212240 90.42%)",
  backgroundTabButton:"#1F1F1F",
  TabButtonActive:"linear-gradient(291.54deg, #101133 27.04%, #232441 86.39%)",
};
