import React from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { useMatchBreakpoints } from '@phamphu19498/bami-uikit'
import Page from '../Layout/Page'
import PageLoaderAnimation from './PageLoaderAnimation.json'


const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  const { isXl } = useMatchBreakpoints();
  const isMobile = isXl === false;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PageLoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <Wrapper>
        <Lottie 
            options={defaultOptions}
            height={ isMobile ? 217 : 300}
            width={isMobile ? 370 : 500}
          />
    </Wrapper>
  )
}

export default PageLoader
