import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const renderColor = () => {
        return "#130A00"
    }
  return (
    <Svg width="28" height="27" viewBox="0 0 28 27" {...props} style={{fill:"none"}}>
<g clipPath="url(#clip0_568_13768)">
<path d="M7.99992 10.5003C10.5772 10.5003 12.6666 8.41099 12.6666 5.83366C12.6666 3.25633 10.5772 1.16699 7.99992 1.16699C5.42259 1.16699 3.33325 3.25633 3.33325 5.83366C3.33325 8.41099 5.42259 10.5003 7.99992 10.5003Z" stroke={renderColor()} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 7.83301C9.10457 7.83301 10 6.93758 10 5.83301C10 4.72844 9.10457 3.83301 8 3.83301C6.89543 3.83301 6 4.72844 6 5.83301C6 6.93758 6.89543 7.83301 8 7.83301Z" stroke={renderColor()} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.47341 9.7596L4.66675 15.8329L8.00008 13.8329L11.3334 15.8329L10.5267 9.75293" stroke={renderColor()} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_568_13768">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
    </Svg>
  );
};

export default Icon;
