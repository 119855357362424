import { CardViewIcon, ListViewIcon } from 'components/Pancake-uikit'
import React from 'react'
import { IconButton } from '@phamphu19498/bami-uikit'
import styled from 'styled-components'
import { ViewMode } from '../types'


interface ToggleViewProps {
  viewMode: ViewMode
  onToggle: (mode: ViewMode) => void
}

const Container = styled.div`
  border:none;
  background:${({ theme }) => (theme.isDark ? theme.colors.input : theme.colors.Neutral)};
  box-sizing: border-box;
  box-shadow: -2px 2px 8px rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  justify-content: space-around;
  padding: 3px 3px 2px;
  width: 72px;
  height: 38px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding:0px !important;
  }
`
const CustomIconButton = styled(IconButton)`
  border-radius:5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  width:32px;
`
const backgroundColor = {
 background:"#3C8B8F",
}
const filterActive = {
  filter: 'invert(80%) sepia(32%) saturate(293%) hue-rotate(356deg) brightness(106%) contrast(95%)',
}

const filterInactive = {
  filter: 'invert(39%) sepia(21%) saturate(213%) hue-rotate(346deg) brightness(93%) contrast(83%)'
}
const ToggleView: React.FunctionComponent<ToggleViewProps> = ({ viewMode, onToggle }) => {
  const handleToggle = (mode: ViewMode) => {
    if (viewMode !== mode) {
      onToggle(mode)
    }
  }
  return (
    <Container>
      <CustomIconButton variant="text" scale="sm" onClick={() => handleToggle(ViewMode.TABLE)} style={viewMode === "TABLE" ? backgroundColor : undefined}>
        <ListViewIcon style={viewMode === 'TABLE' ? filterActive : filterInactive} />
      </CustomIconButton>
      <CustomIconButton variant="text" scale="sm" onClick={() => handleToggle(ViewMode.CARD)} style={viewMode === "CARD" ? backgroundColor : undefined}>
        <CardViewIcon style={viewMode === 'CARD' ? filterActive : filterInactive} />
      </CustomIconButton>
    </Container>
  )
}

export default ToggleView
