import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, Toggle, Text, NotificationDot } from 'components/Pancake-uikit'
import { useTranslation } from 'contexts/Localization'
import ToggleView, { ViewMode } from './ToggleView/ToggleView'



const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${Text} {
    margin-left: 8px;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: 480px){
    width: auto;
  }
`

const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
a {
  padding-left: 12px;
  padding-right: 12px;
  width:100px !important;
  @media only screen and (max-width: 480px){
    font-size: 12px;
    width:70px !important;
  }
}
`
const CustomButtonMenu = styled(ButtonMenu)`
  background: ${({ theme }) => theme.colors.input};
  width: 202px;
  height: 41px;
  align-items: center;
  justify-content: center;
  border-radius:5px;
  padding-left:3px;
  padding-right:3px;
  padding-top:3px;
`

const PoolTabButtons = ({ hasStakeInFinishedPools }) => {
  const { url, isExact } = useRouteMatch()
  const { t } = useTranslation()

  const liveOrFinishedSwitch = (
    <Wrapper>
      <CustomButtonMenu activeIndex={isExact ? 0 : 1} scale="sm" variant="primaryDark">
        <ButtonMenuItem as={Link} to={`${url}`}>
          {t('Live')}
        </ButtonMenuItem>
        <NotificationDot show={hasStakeInFinishedPools}>
          <ButtonMenuItem as={Link} to={`${url}/history`}>
            {t('Finished')}
          </ButtonMenuItem>
        </NotificationDot>
      </CustomButtonMenu>
    </Wrapper>
  )

  return (
    <ViewControls>
      {liveOrFinishedSwitch}
    </ViewControls>
  )
}

export default PoolTabButtons
