
import useTheme from 'hooks/useTheme'
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon = (props) => {
  return (
      <Svg viewBox="0 0 44 44" {...props} width="44px" style={{cursor:"pointer"}}>
<rect width="44" height="44" rx="22" fill="#3C8B8F"/>
<path d="M27 11L31 15L27 19" stroke="#F9DFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 21V19C13 17.9391 13.4214 16.9217 14.1716 16.1716C14.9217 15.4214 15.9391 15 17 15H31" stroke="#F9DFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 33L13 29L17 25" stroke="#F9DFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M31 23V25C31 26.0609 30.5786 27.0783 29.8284 27.8284C29.0783 28.5786 28.0609 29 27 29H13" stroke="#F9DFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>

   </Svg>
  );
};

export default Icon;





