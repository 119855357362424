import { MenuEntry } from 'components/Pancake-uikit'
import { ContextApi } from 'contexts/Localization/types'
import { Colors } from "../Pancake-uikit/theme/types";

export interface LinkStatus {
  text: string;
  color: keyof Colors;
}

export const status = {
  LIVE: <LinkStatus>{
    text: "LIVE",
    color: "failure",
  },
  SOON: <LinkStatus>{
    text: "Coming",
    color: "warning",
  },
  NEW: <LinkStatus>{
    text: "NEW",
    color: "success",
  },
};

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('DASHBOARD'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('EXCHANGE'),
    icon: 'ExchangeIcon',
    href: '/swap',
  },
  {
    label: t('EARNING'),
    icon: 'StakingIcon',
    items: [
      {
        label: t('Farms'),
        href: '/farms',
        isExternal: false
      },
      {
        label: t('Pools'),
        href: '/pools',
        isExternal: false
      },
      {
        label: t('NFT Pools'),
        href: '/nftpools',
        isExternal: false
      }
      // {
      //   label: t('Pawn Pools'),
      //   href: '/pawnpools',
      //   isExternal: false
      // }
    ]
  },
  {
    label: t('PAWN SHOP'),
    icon: 'LendingIcon',
    href: '/pawn',
  },
  {
    label: t('CLEARANCE MARKET'),
    icon: 'MortgageIcon',
    href: '/market',
  },
  {
    label: t('LAUNCHPAD'),
    icon: 'LaunchPadIcon',
    items: [
      {
        label: t('Apply'),
        href: '/apply',
        isExternal: false
      },
      {
        label: t('Private Share'),
        href: '/privateshare/live',
        isExternal: false
      },
      {
        label: t('IDO'),
        href: '/ido',
        isExternal: false
      },
    ]
  },
  {
    label: t("MEMBERSHIP"),
    icon: "MembershipIcon",
    href: "/membership",
  },
  {
    label: t("AIRDROP"),
    icon: "AirdropIcon",
    href: "/airdrop",
  },
  // {
  //   label: t('MORE'),
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: t('WHITEPAPER'),
  //       href: 'https://ltd.livetrade.io/wp-content/uploads/2021/07/LTD-Whitepaper_Eng.pdf',
  //       isExternal: true
  //     },
  //     {
  //       label: t('DOCS'),
  //       href: 'https://docs.livetrade.io/',
  //       isExternal: true
  //     },
  //     {
  //       label: t('SMART CONTRACT'),
  //       href: 'https://bscscan.com/token/0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4',
  //       isExternal: true
  //     }
  //   ]
  // }
]

export default config
