import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Heading, Text } from '@phamphu19498/bami-uikit'
import { backgroundHeader } from 'components/Pancake-uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'


interface PageheaderProp {
  Page:string
  title:string
  subtitle:string
  bannerimg:string
}
const PageHeader: React.FC<PageheaderProp> = ({ Page, title, subtitle, bannerimg }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  return(
    <Container>
      <Row>
          <Positonbackground/>
          <BackGround/>
      </Row>
     <ContainerImg src={bannerimg} alt="logo" />
     <ContainerTitle>
       <CustomHeading>{Page}</CustomHeading>
       <CustomTitle>{title}</CustomTitle>
       <CustomSubtitle bold>{subtitle}</CustomSubtitle>
     </ContainerTitle>
    </Container>
  )
}

export default PageHeader

const Container = styled.div`
  width:100%;
  height:210px;
  padding-left:20px;
  padding-right:20px;
  background:${({ theme }) => theme.colors.secondaryBright};
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom:20px;
  position:relative;
  @media only screen and (max-width: 600px) {
    border-radius: 0px;
    height: 311px;
  }
`
const Positonbackground = styled(backgroundHeader)`
  position: absolute;
  top:10px;
`
const Row = styled(Flex)`
  position:relative;
  opacity: 0.75;
`
const BackGround = styled.div`
  width:80%;
  height:183px;
  background:#3B98BC;
  position: absolute;
  right:0px;
  top:10px;
  @media only screen and (min-width: 768px) and (max-width: 1080px){
    width:calc(100vw - 310px);
  }
  @media only screen and (max-width: 600px) {
    width:calc(100vw - 200px);
  }
`
const ContainerImg = styled.img`
  width: 270px;
  height: 184px;
  position: absolute;
  right: 68px;
  top:10px;
  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    right: 0px
  }
  @media only screen and (max-width: 600px) {
    width: 213px;
    height: 145px;
    left:20px;
  }
`
const ContainerTitle = styled.div`
  width:725px;
  position: absolute;
  top:20px;
  left:60px;
  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    width:400px;
  }
  @media only screen and (max-width: 600px) {
    top:170px;
    left:20px;
    width:305px;
  }
`
const CustomHeading = styled(Heading)`
  font-size:72px;
  color:#3B98BC;
  text-transform: uppercase;
  font-weight:normal;
  text-shadow: 4px 4px 0px #000000;
  font-family: Lalezar;
  @media only screen and (max-width: 1080px) {
    font-size:36px;
    text-shadow: 2px 2px 0px #000000;
  }
`
const CustomTitle = styled(Heading)`
  color: #BB5432;
  font-size: 36px;
  text-shadow: 2px 2px 0px #000000;
  line-height: 40px;
  font-family: Lalezar;
  text-transform: uppercase;
  @media only screen and (max-width: 1080px) {
    font-size:24px;
    text-shadow: 1px 1px 0px #000000;
    line-height: 30px;
  }
`
const CustomSubtitle = styled(Text)`
  font-size:18px;
  color: #130A00;
  margin-top:10px;
  font-family: Lalezar;
  text-transform: uppercase;
  @media only screen and (max-width: 1080px) {
    font-size:16px;
    margin-top:5px;
  }
`