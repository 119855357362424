import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg style={{ width: "20px", height: "21px" }} viewBox="0 0 20 21" fill="none" {...props}>
<path d="M7.77778 2.72217H3.33333C2.71968 2.72217 2.22222 3.21963 2.22222 3.83328V8.27772C2.22222 8.89137 2.71968 9.38883 3.33333 9.38883H7.77778C8.39143 9.38883 8.88889 8.89137 8.88889 8.27772V3.83328C8.88889 3.21963 8.39143 2.72217 7.77778 2.72217Z"/>
<path d="M16.6667 2.72217H12.2222C11.6086 2.72217 11.1111 3.21963 11.1111 3.83328V8.27772C11.1111 8.89137 11.6086 9.38883 12.2222 9.38883H16.6667C17.2803 9.38883 17.7778 8.89137 17.7778 8.27772V3.83328C17.7778 3.21963 17.2803 2.72217 16.6667 2.72217Z"/>
<path d="M7.77778 11.6113H3.33333C2.71968 11.6113 2.22222 12.1088 2.22222 12.7224V17.1669C2.22222 17.7805 2.71968 18.278 3.33333 18.278H7.77778C8.39143 18.278 8.88889 17.7805 8.88889 17.1669V12.7224C8.88889 12.1088 8.39143 11.6113 7.77778 11.6113Z" />
<path d="M16.6667 11.6113H12.2222C11.6086 11.6113 11.1111 12.1088 11.1111 12.7224V17.1669C11.1111 17.7805 11.6086 18.278 12.2222 18.278H16.6667C17.2803 18.278 17.7778 17.7805 17.7778 17.1669V12.7224C17.7778 12.1088 17.2803 11.6113 16.6667 11.6113Z" />

    </Svg>
  )
}

export default Icon
