import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" {...props} style={{fill:"none"}}>
<path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#BB5432" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 8V12" stroke="#BB5432" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 16H12.01" stroke="#BB5432" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
