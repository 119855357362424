import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme()
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" {...props} style={{fill:"none"}}>
<path d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z" stroke={theme.isDark ? "#A19E95" : "#6D655E"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 6.5V12.5L16 14.5" stroke={theme.isDark ? "#A19E95" : "#6D655E"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
