import React from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { NavLink, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@phamphu19498/bami-uikit'
import { useTranslation } from 'contexts/Localization'



const StyledNav = styled.nav`
  margin-bottom: 32px;
  justify-content: center;
  text-align: center;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.input};
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  height:50px;
  padding: 3px 4px !important;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    padding: 2px;
    width: 550px;
  }
  a {
    flex-grow: 1;
    padding-top: 12px;
    padding-bottom: 12px;
    color: ${({ theme }) => (theme.isDark ? '#A39B8F' : '#6D655E')};
    font-weight: 400;
    font-size: 16px;
    line-height: auto;
    text-align: center;
    height: 100%;
    display: inline-block;
  }
  @media only screen and (max-width: 600px) {
   margin-left:10px !important;
   margin-right:10px !important;
   width:auto;
  }
`
const defaultStyle = {
 
}
const styleActiveDark = { 
    
  color: '#F9DFAF', 
  borderRadius: '8px',
  background: '#3C8B8F',
  border: '1px solid rgba(255, 255, 255, 0.35)',
  boxsizing: 'border-box',
  boxShadow: '4px 2px 1px rgba(8, 9, 26, 0.2)'

}
const styleActiveLight = { 
    
  color: '#F9DFAF', 
  borderRadius: '8px',
  background: '#3C8B8F',
  border: '1px solid rgba(255, 255, 255, 0.35)',
  boxsizing: 'border-box',
}


const getActiveIndex = (pathname: string): number => {
  let pathActive = 0
  switch (pathname) {
    case '/swap':
      pathActive = 0
      break;
    default:
      pathActive = 1
      break;
  }
  return pathActive
}

const Nav = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const activeIndex = getActiveIndex(location.pathname)
  const { theme } = useTheme()
  const StyleActive = (isDark: boolean) => {
    if (isDark) {
      return styleActiveDark
    }
  
    return styleActiveLight
  }
  
  return (
    <StyledNav>
      <NavLink style={activeIndex === 0 ? StyleActive(theme.isDark) : defaultStyle} to="/swap" aria-hidden="true">
        <strong>{t('Trade')}</strong>
      </NavLink>
      <NavLink style={activeIndex === 1 ? StyleActive(theme.isDark) : defaultStyle} to="/pool" aria-hidden="true">
        <strong>{t('Liquidity')}</strong>
      </NavLink>
      {/* <NavLink style={activeIndex === 2 ? StyleActive(theme.isDark) : defaultStyle} to="/bridge" aria-hidden="true">
        <strong>{t('Bridge')}</strong>
      </NavLink> */}
    </StyledNav>
  )
}

export default Nav
