import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme()
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" {...props} style={{fill:"none"}}>
<path d="M12 2L2 7L12 12L22 7L12 2Z" stroke={theme.isDark ? "#F9DFAF" : "#130A00"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2 17L12 22L22 17" stroke={theme.isDark ? "#F9DFAF" : "#130A00"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2 12L12 17L22 12" stroke={theme.isDark ? "#F9DFAF" : "#130A00"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
