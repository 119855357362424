import { LinkStatus } from "./types";

export const status = {
  LIVE: <LinkStatus>{
    text: "LIVE",
    color: "failure",
  },
  SOON: <LinkStatus>{
    text: "SOON",
    color: "warning",
  },
  NEW: <LinkStatus>{
    text: "NEW",
    color: "success",
  },
};

export const links = [
  {
    label: 'DASHBOARD',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'ExchangeIcon',
    href: '/swap',
  },
  {
    label: 'EARNS',
    icon: 'StakingIcon',
    items: [
      {
        label: 'FARMS',
        href: '/farms',
      },
      {
        label: 'POOLS',
        href: '/pools',
      },
    ]
  },
  {
    label: 'LAUNCHPAD',
    icon: 'LaunchPadIcon',
    href: '/repo',
  },
  {
    label: 'LENDING',
    icon: 'LendingIcon',
    href: '/membership',
  },
  {
    label: 'COLLATERALS',
    icon: 'MortgageIcon',
    href: '/dipo/live',
  },
  {
    label: "MEMBERSHIP",
    icon: "MembershipIcon",
    href: "/nftmarketplace",
  },
  {
    label: 'MORE',
    icon: 'MoreIcon',
    items: [
      {
        label: 'WHITEPAPER',
        href: 'https://ltd.livetrade.io/wp-content/uploads/2021/07/LTD-Whitepaper_Eng.pdf',
        isExternal: true
      },
      {
        label: 'DOCS',
        href: 'https://docs.livetrade.io/',
        isExternal: true
      },
      {
        label: 'SMART CONTRACT',
        href: 'https://bscscan.com/token/0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4',
        isExternal: true
      }
    ]
  },
  {
    label: "EXTERNAL LINK",
    icon: "MoreIcon",
    href: "https://bscscan.com/token/0x0f7cd24e31293b162dcf6211c6ac5bd8efcb81f4",
    isExternal: true
  },
];
export const socials = [
  {
    label: "Website",
    icon: "WebsiteIcon",
    href: "https://ltd.livetrade.io"
  },
  {
    label: "Telegram",
    icon: "TelegramIcon",
    href: "https://t.me/LiveTradeLTD"
  },
  {
    label: "Twitter",
    icon: "TwitterIcon",
    href: "https://twitter.com/BamiPawn",
  },
  {
    label: "Youtube",
    icon: "YoutubeIcon",
    href: "https://www.youtube.com/channel/UCFOIIUjZyK7H3sHpDgIXKyw",
  },
  {
    label: "Facebook",
    icon: "FacebookIcon",
    href: "https://www.facebook.com/BamiPawn",
  }
];
export const MENU_HEIGHT = 64;
export const MENU_ENTRY_HEIGHT = 48;
export const SIDEBAR_WIDTH_FULL = 240;
export const SIDEBAR_WIDTH_REDUCED = 56;
