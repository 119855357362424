import tokens from "config/constants/tokens"

export const Processes = (t) => [
  {
    title:t("Bonus & discount"),
    description: t("Enjoy numerous incentives and benefits on BAMIs products & services"),
    image: "PrivilegesIcon",
  },
  {
    title:t("Profit sharing"),
    description: t("Participate in productive investments introduced by BAMI & partners"),
    image: "ExclusivityIcon",
  },
  {
    title:t("Redeem & Transfer"),
    description: t("Redeem NFTs for BAMI any time or transfer ownership to share the privileges"),
    image: "RequirementIcon",
  },
  {
    title:t("And more to come"),
    description: t("Enjoy new features that will be continuously developed to better serve our users"),
    image: "RedeemTransferIcon",
  },
]

export const Memberships = {
  Diamond: {
    title: "Diamond",
    address: {
      97: '0x8fD976a783e916dC6C7D73F635c7B963e2781f51',
      56: '0x5aC7e8c57E6fa94E97EB39744aF7cd4eCF73338A'
    }
  },
  Platinum: {
    title: "Platinum",
    address: {
      97: '0x07a3c53752743eb867a42cEBDF55FA2081f33D3C',
      56: '0xFB1c5F914D4F81C838ef3aFDee0aB38CB9493B49'
    }
  },
  Gold: {
    title: "Gold",
    address: {
      97: '0x77Ecd20e19C7C6b3a6ba869584488bFc91E5D0AE',
      56: '0xAb24AB5c0a7c08dBb8d06eF029Ab23041f5030D2'
    }
  },
  Elite: {
    title: "Elite",
    address: {
      97: '0x067a7C89Ffb00209d6759C8F38992DEfE18984c0',
      56: '0xaDEA031Fb82B04133c93B7e12d0b685FFe1E1934'
    }
  },
  Deluxe: {
    title: "Deluxe",
    address: {
      97: '0xB3d361376C87f1375aad6F8868669598dF7bbDbB',
      56: '0x19fc120958d1D74760Bdf86F7cAB62f1ce0bb76C'
    }
  },
  Standard: {
    title: "Standard",
    address: {
      97: '0x8285F1E033a79570aE097bBFfe77D7e89125e929',
      56: '0xB278d71a4217e66481Cdf9436E5a35d3D9edE45b'
    }
  },
}

export const Conditions = [
  {
    order_id:1,
    title: Memberships.Diamond.title,
    symbolLevel:"LTDDiamond",
    image: '/images/membership/ranks/Diamond.svg',
    videos:"https://dapp-livetrade.s3.ap-southeast-1.amazonaws.com/Diamond.webm",
    backgroundurl:"url(./images/membership/ranks/background/Diamond.png)",
    imageNFT:"/images/membership/ranks/background/Diamond.png",
    quantity: 35000,
    maxarmorial: 50,
    ltdRequired: 35000,
    Maximumsupply:2,
    Remaining: 0,
    contractAddress: Memberships.Diamond.address,
    backgroundRemaining:"linear-gradient(158.93deg, #7E0B0B 0%, #080530 68.87%)",
    tokenUpgrade: tokens.bami,
    totalPerUser: 0,
    claimed: 0,
    maxAllocated: 200,
    approveReturn: false,
    allowance: 0,
    nftLists: [],
    tokenList: [],
    nfts:'url(./images/YourNFTs/Diamond.png)',
    content:"You’ve got the power!",
    Infor: {
      DataInfor:[
        {
          id:1,
          content:"Seed/Private Allocations (varies for projects)",
          details:["DIPO Projects", "Joint Projects"]
        },
        {
          id:2,
          content:"Exclusive bonuses and rewards",
        },
        {
          id:3,
          content:"Better interest rates for Lending and Staking",
        },
        {
          id:5,
          content:"Incentives on BAMI App",
          details:["Better rates for trading", "Purchase DIPO projects' tokens on the app","Discount on premium features (cashback, social trading, etc.)"]
        },
        {
          id:6,
          content:"Exclusive discount & features on NFT trading",
          details:["Membership NFTs", "NFTs of other projects"]
        }
      ]
     }    
  },
  {
    order_id:2,
    title: Memberships.Platinum.title,
    symbolLevel:"LTDPlatinum",
    image: '/images/membership/ranks/Platinum.svg',
    videos:"https://dapp-livetrade.s3.ap-southeast-1.amazonaws.com/Platinum.webm",
    backgroundurl:"url(./images/membership/ranks/background/Platinum.png)",
    imageNFT:"/images/membership/ranks/background/Platinum.png",
    quantity: 5000,
    maxarmorial: 150,
    Maximumsupply:5,
    maxAllocated: 150,
    ltdRequired: 25000,
    Remaining: 0,
    backgroundRemaining:"linear-gradient(161.03deg, #5A1230 0%, #040325 75.55%)",
    contractAddress: Memberships.Platinum.address,
    tokenUpgrade: tokens.bami,
    totalPerUser: 0,
    nftLists: [],
    tokenList: [],
    claimed: 0,
    approveReturn: false,
    allowance: 0,
    nfts:'url(./images/YourNFTs/Platinum.png)',
    content:"One more step to go",
    Infor: {
      DataInfor:[
        {
          id:1,
          content:"Seed/Private Allocations (varies for projects)",
          details:["DIPO Projects", "Joint Projects"]
        },
        {
          id:2,
          content:"Exclusive bonuses and rewards",
        },
        {
          id:3,
          content:"Better interest rates for Lending and Staking",
        },
        {
          id:5,
          content:"Incentives on BAMI App",
          details:["Better rates for trading", "Purchase DIPO projects' tokens on the app","Discount on premium features (cashback, social trading, etc.)"]
        },
        {
          id:6,
          content:"Exclusive discount & features on NFT trading",
          details:["Membership NFTs", "NFTs of other projects"]
        }
      ]
     }    
  }, {
    order_id:3,
    title: Memberships.Gold.title,
    symbolLevel:"LTDGold",
    image: '/images/membership/ranks/Gold.svg',
    videos:"https://dapp-livetrade.s3.ap-southeast-1.amazonaws.com/Gold.webm",
    backgroundurl:"url(./images/membership/ranks/background/Gold.png)",
    imageNFT:"/images/membership/ranks/background/Gold.png",
    quantity: 20000,
    maxarmorial: 250,
    Maximumsupply:5,
    maxAllocated: 100,
    Remaining: 0,
    ltdRequired: 20000,
    backgroundRemaining:"linear-gradient(161.03deg, #39094A 0%, #0C031F 75.55%)",
    contractAddress: Memberships.Gold.address,
    tokenUpgrade: tokens.bami,
    totalPerUser: 0,
    claimed: 0,
    nftLists: [],
    tokenList: [],
    approveReturn: false,
    allowance: 0,
    nfts:'url(./images/YourNFTs/Gold.png)',
    content:"Climb to the top",
    Infor: {
      DataInfor:[
        {
          id:1,
          content:"Seed/Private Allocations (varies for projects)",
          details:["DIPO Projects", "Joint Projects"]
        },
        {
          id:2,
          content:"Exclusive bonuses and rewards",
        },
        {
          id:3,
          content:"Better interest rates for Lending and Staking",
        },
        {
          id:5,
          content:"Incentives on BAMI App",
          details:["Better rates for trading", "Purchase DIPO projects' tokens on the app","Discount on premium features (cashback, social trading, etc.)"]
        },
        {
          id:6,
          content:"Exclusive discount & features on NFT trading",
          details:["Membership NFTs", "NFTs of other projects"]
        }
      ]
     }    
  }, {
    order_id:4,
    title: Memberships.Elite.title,
    symbolLevel:"LTDElite",
    image: '/images/membership/ranks/Silver.svg',
    videos:"https://dapp-livetrade.s3.ap-southeast-1.amazonaws.com/Elite.webm",
    backgroundurl:"url(./images/membership/ranks/background/Elite.png)",
    imageNFT:"/images/membership/ranks/background/Elite.png",
    quantity: 14000,
    maxarmorial: 400,
    Maximumsupply:15,
    Remaining: 0,
    maxAllocated: 50,
    ltdRequired: 14000,
    backgroundRemaining:"linear-gradient(161.03deg, #300F59 0%, #1D0C42 75.55%)",
    contractAddress: Memberships.Elite.address,
    tokenUpgrade: tokens.bami,
    totalPerUser: 0,
    nftLists: [],
    tokenList: [],
    claimed: 0,
    approveReturn: false,
    allowance: 0,
    nfts:'url(./images/YourNFTs/Elite.png)',
    content:"Go and grow",
    Infor: {
      DataInfor:[
        {
          id:1,
          content:"Seed/Private Allocations (varies for projects)",
          details:["DIPO Projects", "Joint Projects"]
        },
        {
          id:2,
          content:"Exclusive bonuses and rewards",
        },
        {
          id:3,
          content:"Better interest rates for Lending and Staking",
        },
        {
          id:5,
          content:"Incentives on BAMI App",
          details:["Better rates for trading", "Purchase DIPO projects' tokens on the app","Discount on premium features (cashback, social trading, etc.)"]
        },
        {
          id:6,
          content:"Exclusive discount & features on NFT trading",
          details:["Membership NFTs", "NFTs of other projects"]
        }
      ]
     }    
  }, {
    order_id:5,
    title: Memberships.Deluxe.title,
    symbolLevel:"LTDDeluxe",
    image: '/images/membership/ranks/Deluxe.svg',
    videos:"https://dapp-livetrade.s3.ap-southeast-1.amazonaws.com/Deluxe.webm",
    backgroundurl:"url(./images/membership/ranks/background/Deluxe.png)",
    imageNFT:"/images/membership/ranks/background/Deluxe.png",
    quantity: 7000,
    maxarmorial:750,
    Maximumsupply:15,
    Remaining: 0,
    ltdRequired: 7000,
    backgroundRemaining:"#1C1248",
    contractAddress: Memberships.Deluxe.address,
    tokenUpgrade: tokens.bami,
    totalPerUser: 0,
    nftLists: [],
    tokenList: [],
    claimed: 0,
    approveReturn: false,
    allowance: 0,
    nfts:'url(./images/YourNFTs/Deluxe.png)',
    content:"Make steps forward",
    Infor: {
      DataInfor:[
        {
          id:1,
          content:"Seed/Private Allocations (varies for projects)",
          details:["DIPO Projects"]
        },
        {
          id:2,
          content:"Exclusive bonuses and rewards",
        },
        {
          id:3,
          content:"Better interest rates for Lending and Staking",
        },
        {
          id:5,
          content:"Incentives on BAMI App",
          details:["Better rates for trading", "Purchase DIPO projects' tokens on the app","Discount on premium features (cashback, social trading, etc.)"]
        },
        {
          id:6,
          content:"Exclusive discount & features on NFT trading",
          details:["Membership NFTs"]
        }
      ]
     }    
  }, {
    order_id:6,
    title: Memberships.Standard.title,
    symbolLevel:"LTDStandard",
    image: '/images/membership/ranks/Standard.svg',
    videos:"https://dapp-livetrade.s3.ap-southeast-1.amazonaws.com/Standard.webm",
    backgroundurl:"url(./images/membership/ranks/background/Standard.png)",
    imageNFT:"/images/membership/ranks/background/Standard.png",
    quantity: 3500,
    maxarmorial: 500,
    Maximumsupply:15,
    Remaining: 0,
    ltdRequired: 3500,
    backgroundRemaining:"linear-gradient(160.98deg, #1C1A6D 11.61%, #0B1740 82.66%)",
    contractAddress: Memberships.Standard.address,
    tokenUpgrade: tokens.bami,
    nftLists: [],
    tokenList: [],
    totalPerUser: 0,
    claimed: 0,
    approveReturn: false,
    allowance: 0,
    nfts:'url(./images/YourNFTs/Standard.png)',
    content:"Start from the bottom ",
    Infor: {
      DataInfor:[
        {
          id:1,
          content:"Seed/Private Allocations (varies for projects)",
          details:["DIPO Projects"]
        },
        {
          id:2,
          content:"Exclusive bonuses and rewards",
        },
        {
          id:3,
          content:"Better interest rates for Lending and Staking",
        },
        {
          id:5,
          content:"Incentives on BAMI App",
          details:["Better rates for trading", "Purchase DIPO projects' tokens on the app","Discount on premium features (cashback, social trading, etc.)"]
        },
        {
          id:6,
          content:"Exclusive discount & features on NFT trading",
          details:["Membership NFTs"]
        }
      ]
     }    
  },
]

export const YourRight = (t) => [
  {
    id:1,
    YourRight:t("Number of BAMI required"),
    Standard: "3,500",
    Deluxe:"7,000",
    Elite:"14,000",
    Gold:"20,000",
    Platinum:"25,000",
    Diamond:"35,000",
  },
  {
    id:2,
    YourRight:t("Maximum supply"),
    Standard:"750",
    Deluxe:"500",
    Elite:"400",
    Gold:"250",
    Platinum:"150",
    Diamond:"50",
  },
  {
    id:3,
    YourRight:t("Maximum amount per user"),
    Standard:"15",
    Deluxe:"15",
    Elite:"15",
    Gold:"5",
    Platinum:"5",
    Diamond:"2",
  },
  {
    id:4,
    YourRight:t("Seed/Private Allocations (varies for projects)"),
    details:{
      DataDetails:[
        {id:5, Name:t("DIPO Projects"), Standard:true, Deluxe:true, Elite:true, Gold:true, Platinum:true, Diamond:true},
        {id:6, Name:t("Joint Projects"), Standard:false, Deluxe:false, Elite:true, Gold:true, Platinum:true, Diamond:true}
      ]
    }
  },
  {
    id:7,
    YourRight:t("Exclusive bonuses and rewards"),
    Standard:"check",
    Deluxe:"check",
    Elite:"check",
    Gold:"check",
    Platinum:"check",
    Diamond:"check",
  },
  {
    id:8,
    YourRight:t("Better interest rates for Lending and Staking"),
    Standard:"check",
    Deluxe:"check",
    Elite:"check",
    Gold:"check",
    Platinum:"check",
    Diamond:"check",
  },
  {
    id:9,
    YourRight:t("Incentives on BAMI App"),
    details:{
      DataDetails:[
        {id:10, Name:t("Better rates for trading"), Standard:true, Deluxe:true, Elite:true, Gold:true, Platinum:true, Diamond:true},
        {id:11, Name:t("Purchase DIPO projects' tokens on the app"), Standard:true, Deluxe:true, Elite:true, Gold:true, Platinum:true, Diamond:true},
        {id:12, Name:t("Discount on premium features (cashback, social trading, etc.)"), Standard:true, Deluxe:true, Elite:true, Gold:true, Platinum:true, Diamond:true}
      ]
    }
  },
  {
    id:13,
    YourRight:t("Exclusive discount & features on NFT trading"),
    details:{
      DataDetails:[
        {id:14, Name:t("Membership NFTs"), Standard:true, Deluxe:true, Elite:true, Gold:true, Platinum:true, Diamond:true},
        {id:15, Name:t("NFTs of other projects"), Standard:false, Deluxe:false, Elite:true, Gold:true, Platinum:true, Diamond:true},
      ]
    }
  }
]
