import React from 'react'
import { Trade, TradeType } from '@pancakeswap/sdk'
import { Text, Box, Flex } from '@phamphu19498/bami-uikit'
import { useTranslation } from "contexts/Localization";
import { Field } from 'state/swap/actions'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/prices'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const {t} = useTranslation()

  return (
    <Box width="100%">
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="text">
            {isExactIn ? t('Minimum received') : t('Maximum sold')}
          </Text>
          <QuestionHelper
            text={t("Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.")}
            ml="4px"
          />
        </RowFixed>
        <RowFixed>
          <Text fontSize="16px">
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ?? '-'}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="text">
            {t('Price Impact')}
          </Text>
          <QuestionHelper
            text={t("The difference between the market price and estimated price due to trade size.")}
            ml="4px"
          />
        </RowFixed>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="text">
            {t('Liquidity Provider Fee')}
          </Text>
          <QuestionHelper
            text={
              <>
                <Text mb="12px" color="#000">{t('A fee of 0.25% will be charged for each transaction')}</Text>
                <Text color="#000">{t('- 0.17% to liquidity providers')}</Text>
                <Text color="#000">{t('- 0.03% to the Treasury')}</Text>
                <Text color="#000">{t('- 0.05% towards BAMI buyback and burn')}</Text>
              </>
            }
            ml="4px"
          />
        </RowFixed>
        <Text fontSize="16px">
          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
        </Text>
      </RowBetween>
    </Box>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()
  const {t} = useTranslation()
  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <Flex width="100%">
      {trade && (
        <Box width="100%">
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <RowBetween style={{ padding: '0 16px', flexDirection:"column" }}>
                <Flex alignItems="center">
                  <Text fontSize="16px" color="textSubtle">
                    Route
                  </Text>
                  <QuestionHelper
                    text={t("Routing through these tokens resulted in the best price for your trade.")}
                    ml="4px"
                  />
                </Flex>
                <SwapRoute trade={trade} />
              </RowBetween>
            </>
          )}
        </Box>
      )}
    </Flex>
  )
}
