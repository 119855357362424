import React from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import Page from 'components/Layout/Page'
import { Button, Heading, Text, LogoIcon, useMatchBreakpoints } from '@phamphu19498/bami-uikit'
import ButtonDigitalAssetsAndTokenizedStocks from 'views/Farms/components/ButtonDigitalAssetsAndTokenizedStocks'
import { useTranslation } from 'contexts/Localization'
import Nav from 'components/Menu/SubNav'
import ComingSoonAnimation from '../../config/ComingSoonAnimation.json'

const StyledComingSoon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: flex-start;
  align-items:flex-start;
  margin-bottom:30px;
  @media only screen and (max-width: 600px) {
    width:100%;
  }
`
const ContainerNav = styled.span`
  width: 100%;
`
const CustomPage = styled(Page)`
  @media only screen and (max-width: 600px) {
    padding-left:0px;
    padding-right:0px;
  }
`
const Bridge = () => {
    const { t } = useTranslation()
    const { isXl } = useMatchBreakpoints();
    const isMobile = isXl === false;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: ComingSoonAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    return (
        <CustomPage>
            <StyledComingSoon>
              <ContainerNav><Nav /></ContainerNav>
              <Lottie options={defaultOptions}
                  height={ isMobile ? 225 : 291}
                  width={isMobile ? 360 : 467}
            />
            </StyledComingSoon>
        </CustomPage>
    )
}



export default Bridge
